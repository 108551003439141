/*
 * KTU vieningo prisijungimo sistema SimpleSAMLphp theme
 * Generated by: simplesaml_theme_generate.sh v20230725
 * At: Fri Nov 24 15:09:55 EET 2023
 *
 * This CSS file is included after SimpleSAMLphp's default CSS and merely
 * overrides the default styling for this theme.
 */

:root {
  --theme-colour: #005a5b;
  --theme-up-complement: hsl(178.42020, 98.27600%, 22.74500%);
  --theme-dn-complement: hsl(118.42020, 98.27600%, 22.74500%);
}

/*
 * if your logo is displaying wrong, you most likely need to change
 * the width and height dimensions here.
 */
.logo-icon {
  padding: 0.5rem 0;
  height: calc(6rem - 1rem);
  width: auto;
}

#logo a:hover,
#logo a:focus {
  background-color: inherit;
  padding: inherit;
}

#header,
#footer {
  background: var(--theme-colour);
}

#menu {
  background: var(--theme-colour);
}

#menu .pure-menu-selected,
#menu .pure-menu-heading {
  background: var(--theme-up-complement);
}

#menu .pure-menu ul,
#menu .pure-menu .menu-item-divided {
  border-top: 1px solid var(--theme-dn-complement);
}

.pure-button-primary,
.pure-button-red {
  background-color: var(--theme-colour);
}

.pure-button-red:hover,
.pure-button-red:focus {
  background: var(--theme-dn-complement);
}

.logo-footer,
.copyrights {
  display: none;
}

.footer-links {
  padding-top: 0.5rem;
  height: 3.5rem;
  font-size: .8rem;
}

#header-wrap {
  /* width: 90%; */
  max-width: 1100px;
  margin: auto;
  position: relative;
  height: 96px;
  /* background: var(--theme-colour) url('../images/ktuheader.jpg') no-repeat right bottom; */
  background-size: 100% 100%;
}

.pure-button,
.pure-form input.edge,
.pure-form textarea.edge {
  border-radius: 5px 5px 5px 5px;
}

dl dd {
  color: #666;
  margin-left: 3em;
}

/* #layout {
  margin: 0 auto 0;
} */

/* custom css nuo 2024-03 */
/* /var/www/simplesamlphp/modules/ktu/public/assets/css/output.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

.button-secondary-jg {
  background-color: white;
  color: var(--theme-colour);
  font-weight: 600;
}

.button-secondary-jg:hover {
  background-color: #168486
}

.button-secondary-jg:focus {
  background-color: #168486e5
}

.jg-right {
  float: none;
  margin: 0 auto;
}

.jg-buttons-collumn {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 0px;
  margin-left: auto;
  gap: 10px;

  align-items: flex-end;
  margin-top: 8px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .jg-buttons-collumn {
    align-items:start;
    margin-top: 28px;

    /* background-color: red; */
    gap: 32px;

  }
  .jg-right {
    float: right;
  }
}

.pure-form input:not([type]):focus{
  border-color: inherit;
}

/* #content {
  padding-top: unset;
  padding-left: 1px;
} */

.language-button {
  /* Style the button as desired */
}

.language-dropdown {
  /* Style the dropdown as desired */
  position: absolute; /* Absolute positioning for Popper.js */
  display: none; /* Initially hidden */
  
}

.language-dropdown.show {
  display: block; /* Show the dropdown on button click */
}

.language-dropdown li {
  /* padding: 5px 10px;  */
  /* Adjust padding as needed */

}

.language-dropdown a {
  text-decoration: none; /* Remove underline */
  color: #333; /* Set text color */
}

html {
  overflow: hidden;
  height: 100%;
}
body {
  overflow: auto;
  height: 100%;
}

/* unset bs3 setting */
.modal-open {
 overflow: auto; 
}

.ktu-active{
  background-color: rgb(15 118 110);
}

#lostTOTPdropdown {
  background: #737373;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  display: none;
}

#lostTOTPdropdown[data-show] {
  display: block;
}

#arrow,
      #arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
      }

      #arrow {
        visibility: hidden;
      }

      #arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }

      #lostTOTPdropdown[data-popper-placement^='top'] > #arrow {
        bottom: -4px;
      }

      #lostTOTPdropdown[data-popper-placement^='bottom'] > #arrow {
        top: -4px;
      }

      #lostTOTPdropdown[data-popper-placement^='left'] > #arrow {
        right: -4px;
      }

      #lostTOTPdropdown[data-popper-placement^='right'] > #arrow {
        left: -4px;
      }

.tokenQR{
  image-rendering: pixelated;
}

.QRanchor{
  @apply text-teal-700 
   hover:underline hover:decoration-solid hover:decoration-teal-700 hover:decoration-1 hover:underline-offset-1 hover:bg-white hover:text-teal-700 hover:p-0
   focus:underline focus:decoration-double focus:decoration-teal-700 focus:decoration-1 focus:underline-offset-1 focus:bg-white focus:rounded-sm focus:text-teal-700 focus:p-0 
   focus-visible:outline-none;
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  /* #header-IE {
    background-color: #005a5b;

  }

  #footer-IE {
    background-color: #005a5b;
  } */

  .bg-teal-800{
    background-color: #005a5b;
    
  }

  .bg-white{
    background-color: #fff;
  }

  .text-white{
    color: #fff;
  }

  .text-neutral-800{
    color: #262626
  }

  .ring-1{
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    border-style: solid;
    padding: 0.5em 0.6em;
    border-radius: 4px;
  }

  .my-auto {
    /* text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 10em; */
    margin: 0 1em 0 0;
  }
  
  .text-teal-800 {
    color: #005a5b;
  }

  .text-teal-700 {
    color: #0f766e;
  }

  }

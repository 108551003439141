$primaryBackground: #b8002c; $transitionBackground: #db0100; $secondaryBackground: #e8410c;
@import "../../node_modules/reset-css/reset.css";
@import "../../node_modules/purecss/build/pure.css";
$fa-font-path: '../fonts/';
@import "../../node_modules/\@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/\@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/highlight.js/styles/base16/zenburn.css";

/*************
 * VARIABLES *
 *************/

$light-yellow: "#fffdbf";

/************************************************************
 * GENERAL
 ************************************************************/
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: sans-serif;
  line-height: 1.5;
  min-height: 100%;
}

.wrap {
  width: 80%;
  max-width: 1100px;
  margin: auto;
  position: relative;
}

.logospace {
  float: left;
}

html[dir="rtl"] .logospace {
  float: right;
}

.menuspace {
  float: right;
}

html[dir="rtl"] .menuspace {
  float: left;
}

.center {
  padding: auto;
  text-align: center;
}

.v-center {
  /* specify a height to center vertically */
  display: table-cell;
  vertical-align: middle;
}

i {
  font-style: italic;
}

h1 {
  margin: 1em 0;
  font-size: 2em;
  font-weight: 900;
}

h2 {
  margin: 1em 0;
  font-size: 1.5em;
  font-weight: 700;
  color: #1c1c1c;
  border-bottom: solid 1px #bbb;
}

h3 {
  font-weight: 500;
  color: #2e3436;
  padding: 0;
  margin-top: 0;
}

p {
  padding: 0.5em 0;
  margin-bottom: 1em;
}

a {
  color: midnightblue;

  &:hover,
  &:focus,
  &.pure-menu-link:hover,
  &.pure-menu-link:focus,
  .pure-menu-selected &.pure-menu-link:hover,
  .pure-menu-selected &.pure-menu-link:focus {
    color: white;
    background-color: #444;
    padding: 0.5em 1em;
  }

  &:hover,
  &:focus {
    padding: 0.15rem;
  }
}

.overflow {
  overflow: hidden;
}

ul {
  padding-left: 1.5em;
}

strong,
b {
  font-weight: bold;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0 0.2rem;
}

.xmldata {
  font-family: monospace;
}

/* ***********************************************************
 * SLIDING SIDE-MENU FOR SMALL SCREENS
 *************************************************************/

/*
Add transition to containers so they can push in and out.
*/
#layout,
#menu,
#foot,
.menu-link {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

/*
This is the parent `<div>` that contains the menu and the content area.
*/
#layout.active #menu {
  right: 11em;
  width: 11em;
}

/*
The `#menu` `<div>` is the parent `<div>` that contains the `.pure-menu` that
appears on the left side of the page.
*/
#menu {
  margin-right: -11em; /* "#menu" width */
  width: 11em;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* so the menu or its navicon stays above all content */
  background: $secondaryBackground;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*
All anchors inside the menu should be styled like this.
*/
#menu a {
  color: #fff;
  border: none;
  padding: 0.6em 0 0.6em 0.6em;
}

/*
Remove all background/borders, since we are applying them to #menu.
*/
#menu .pure-menu,
#menu .pure-menu ul {
  border: none;
  background: transparent;
}

/*
Add that light border to separate items into groups.
*/
#menu .pure-menu ul,
#menu .pure-menu .menu-item-divided {
  border-top: 1px solid $transitionBackground;
}

/*
This styles the selected menu item `<li>`.
*/
#menu .pure-menu-selected,
#menu .pure-menu-heading {
  background: $primaryBackground;
  color: black;
}

/*
This styles a link within a selected menu item `<li>`.
*/
#menu .pure-menu-selected a {
  color: #fff;
}

/*
This styles the menu heading.
*/
#menu .pure-menu-heading {
  font-size: 110%;
  color: #fff;
  margin: 0;
  text-transform: none;
}

/* -- Dynamic Button For Responsive Menu -- */

/*
The button to open/close the Menu is custom-made and not part of Pure. Here's
how it works:
*/

/*
`.menu-link` represents the responsive menu toggle that shows/hides on
small screens.
*/
.menu-link {
  position: relative;
  display: block; /* show this only on small screens */
  top: 0;
  right: 0; /* "#menu width" */
  background: transparent;
  z-index: 10;
  height: 2rem;
  padding: 2rem 0;
  text-decoration: none;

  &:hover,
  &:focus {
    padding: 2rem 0;
    background: none !important;
  }
}

.menu-link span {
  position: relative;
  display: block;
  color: #fff;
}

.menu-link span,
.menu-link span::before,
.menu-link span::after {
  background-color: transparent;
}

.menu-link span:focus,
.menu-link span:hover {
  color: #010101cc;
}

/* -- Responsive Styles (Media Queries) ------------------------------------- */

@media screen and (max-width: 40em) {
  #layout.active {
    position: relative;
    right: 11em;

    /* moves to right as many ems as the one used by the menu */
  }

  #menuLink.menu-link.active {
    position: fixed;
    right: 13em;
  }

  #foot.active {
    margin-right: 11em;
  }
}

/************************************************************
HEADER
*************************************************************/
#header {
  height: 6rem;
  background: linear-gradient(141deg, $primaryBackground 0%, $transitionBackground 51%, $secondaryBackground 75%);
}

.logo-header {
  min-width: 8em;
  height: 6rem;
  max-width: 70%;
}

#logo {
  font-family: sans-serif;
  font-size: 2.5em;
  color: white;
  text-shadow: 0 3px 2px #532900;
}

.simple {
  font-weight: 300;
}

.saml {
  font-family: Verdana, sans-serif;
  letter-spacing: -0.12em;
  font-weight: 600;
  margin-left: -0.05em;
}

.language-menu {
  font-family: sans-serif;
  font-weight: 400;
  min-width: 10rem;
  padding: 0em 0.6em !important;
}

.language-bar {
  height: 6rem;
}

/************************************************************
CONTENT
*************************************************************/
#layout {
  right: 0;
  padding-right: 0;
  min-height: 100%;

  /* negative margin = footer height + padding-top + padding-bottom */
  margin: 0 auto -6rem;
}

#content {
  padding-top: 2em;
  padding-bottom: 2rem;
}

.message-box {
  background-color: #f4f4f4;
  border-left: 0.3125rem solid #444;
  box-shadow: 0 5px 8px -6px #0003;
  margin: 1rem 0;
  padding: 1.3rem;
  position: relative;
}

.message-box.error {
  background-color: #f7e4e1;
  border-left-color: #cc4b37;
}

.message-box.warning {
  background-color: $light-yellow;
  border-left-color: #f9f56b;
}

.message-box.success {
  background-color: #daf7e6;
  border-left-color: #46cc48;
}

.auth_methods {
  margin-top: 2em;
  width: 35%;
}

.code-box {
  margin-bottom: 1em;
  border: 1px solid #ccc;

  a {
    padding: 0.5em;
  }
}

.code-box-content {
  word-break: break-all;
  font-size: 1em;
  line-height: 1.15;
  padding: 0.5em 1em;
  display: inline-block;
  min-height: 1em;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  font-family: monospace;

  &::selection {
    color: black;
    background: $light-yellow;
  }
}

.code-box-title {
  border-bottom: 1px solid #ccc;
  background-color: #e0e0e0;
  padding: 0.5em 0 0.5em 0.5em;
}

pre#xmlmetadata {
  width: 98%;
}

/************************************************************
 * FOOTER
 ************************************************************/

#bottom,
#push {
  height: 6rem;
}

#footer {
  width: 100%;
  background: linear-gradient(141deg, $primaryBackground 0%, $transitionBackground 51%, $secondaryBackground 75%);
  height: 4rem;
  padding: 2rem 0 0;
  text-align: center;
  color: white;

  a,
  a:visited {
    color: white;

    &:focus,
    &:hover {
      background-color: white;
      color: black;
      padding: 0.15rem;
      margin-left: -0.15rem;
      margin-top: -0.15rem;
      text-decoration: none;
    }
  }
}

.copyrights {
  padding-top: 0.5rem;
  height: 3.5rem;
  font-size: 0.8rem;
}

.logo-footer-space {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

html[dir="rtl"] .logo-footer-space {
  right: auto;
  left: 0;
}

.logo-footer {
  height: 4rem;
}

/* ***********************************************************
FORMS
************************************************************ */
.text-area {
  margin-top: 0.5em;
  width: 100%;
  font-size: 0.9em;
  line-height: 1.15;
}

.file-upload input[type="url"][disabled] {
  cursor: pointer;
  color: inherit;
}

input[type="file"] {
  color: black;
}

.center-form {
  display: flex;
  justify-content: center;
}

.login-form-start,
.login-form-submit {
  margin-top: 3ex;
}

.v-center-right {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

/* PURE */
.pure-button,
.pure-form input.edge,
.pure-form textarea.edge {
  border-radius: 0;
}

.pure-form input[type="checkbox"] {
  margin-right: 0.8ex;
  vertical-align: baseline;
}

.pure-control-group {
  margin-bottom: 1ex;
}

.pure-form-aligned .pure-controls {
  margin: 0 0 0 11em;
}

.pure-select {
  float: right;
}

html[dir="rtl"] .pure-select {
  float: left;
}

/************************************************************
 * BUTTONS
 ************************************************************/
.code-box-title .clipboard-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  height: inherit;
  margin-top: -2em;
  margin-right: 4px;
}

.pure-button {
  margin-left: 1ex;

  &:hover,
  &:focus {
    background-color: #555;
    padding: 0.5em 1em;
    color: #fff;
  }
}

.pure-button-red {
  background-color: #db0100;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #555;
    padding: 0.5em 1em;
  }
}

.pure-button.hollow {
  background-color: #fff;
  color: #6f6f6f;
  border: solid 1px #e6e6e6;
}

.pure-button.hollow:hover {
  background-image: none;
}

.pure-button.hollow[disabled] {
  cursor: auto;
  opacity: initial;
  pointer-events: auto;
  -webkit-user-drag: auto;
  -webkit-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.pure-button.hljs {
  display: inline-block;
  border: 0;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    color: black;
  }
}

.pure-button-group .pure-button:first-child,
.pure-button-group .pure-button:last-child {
  border-radius: 0;
}

.pure-button-group.two-elements .pure-button {
  margin: 0;
  line-height: unset;
  border: 1px solid #e6e6e6;
}

.pure-button-group.two-elements form {
  display: inline;
}

.pure-button-group.two-elements .pure-button:first-child {
  border-right: none;
}

.pure-button-group.two-elements .pure-button:last-child {
  border-right: 1px solid #e6e6e6;
}

.pure-button-group .pure-button.show-files {
  max-width: 450px;
  overflow: hidden;
}

.top-right-corner {
  position: absolute;
  right: 1.75em;
}

input#postLoginSubmitButton {
  display: none;
}

/************************************************************
 * IMAGES
 ************************************************************/
.fa {
  font-family: "Font Awesome 6 Free", sans-serif !important;
  font-weight: 400;
}

span.fa,
i.fa {
  padding: 0 0.5em;
}

.message-box span.fa,
.message-box i.fa {
  padding: 0;
}

/************************************************************
 * TABLES
 ************************************************************/
.pure-table-attributes {
  table-layout: fixed;
  width: 100%;
}

.attrname {
  text-align: right;
}

.attrvalue {
  overflow-wrap: break-word;
}

table.attributes ul {
  padding: inherit;
}

/************************************************************
 * MEDIA QUERIES
 ************************************************************/

@media screen and (max-width: 40em) {
  .pure-form .pure-input-sm-1-1 {
    width: 100%;
  }
}

@media screen and (max-width: 40em) {
  .wrap {
    width: 90%;
  }

  .pure-form-aligned .pure-controls {
    margin: 0.5em 0 0;
    float: left;
  }

  #layout {
    padding-top: 0;
    margin-bottom: -4rem;
  }

  .auth_methods {
    width: 60%;
  }

  #logo {
    font-size: 1.8em;
  }

  #footer {
    height: 2.5rem;
    padding-top: 1.5rem;

    .copyrights {
      height: 1rem;
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }

  .input-sm-placeholder {
    display: inline-block;
  }
}

@media screen and (min-width: 40em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 40em) {
  .show-for-large {
    display: none !important;
  }
}

/*********
 * Utils *
 *********/

div.preferredidp {
  border: 1px dashed #ccc;
  background: #eee;
  padding: 2px 2em;
}

.clear {
  clear: both;
}

.breathe-top {
  margin-top: 1em;
}

.expandable {
  border: solid 1px #bbb;
  width: 100%;

  .general {
    padding: 1em;
  }

  .content {
    display: none;
    padding: 1em;
  }

  .expander {
    cursor: pointer;
    text-align: center;
    padding: 0.25em;
    display: block;
    color: black;
    background-color: #f4f4f4;
    border-top: solid 1px #bbb;

    &:focus,
    &:hover {
      background-color: #555;
      color: white;
    }

    &::after {
      content: "\f078";
      font-family: "Font Awesome 6 Free", sans-serif;
      font-weight: 400;
    }
  }

  &.expanded {
    .content {
      display: block;
      border-left: solid 0.25em #555;
      border-right: solid 0.25em #555;
    }

    .expander {
      border-bottom: none;
      border-top: solid 1px #bbb;
      border-left: solid 0.25em #555;
      border-right: solid 0.25em #555;

      &::after {
        content: "\f077";
      }
    }
  }
}

.hidden {
  display: none;
}

.idp {
  border: 1px solid silver;
  padding: 1ex;
  margin: 1ex;
}

.idp .idpname {
  font-size: larger;
  font-weight: bold;
}

.idp .idpdesc {
  font-size: small;
  color: gray;
}

.right {
  float: right;
}

.left {
  float: left;
}
